import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, Seo } from 'components';
import Header from 'components/Header';
import HeaderNew from 'components/HeaderNew';

import EmailForm from './components/EmailForm';
import { mobile, smMobile } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import {
  useFirstVisitDate,
  usePageView,
  useQuizData,
  useTrackingQueryParams,
} from 'utils/hooks';
import { Events } from 'utils/events';
import { Quiz } from 'types/quiz';
import BackGroundQuizImage from '../../../assets/images/lastingChange/quiz/BG-des3.png';
import BlackFridayBanner from 'components/BlackFridayBanner';
import Tracking from 'utils/tracking';
import { cliendCodeToUrl } from 'utils/cliendCodeToUrl';
import { normalizeStates } from 'utils/localization';
import { handleError } from 'utils/error';

const HeaderContainer = styled.div`
  position: relative;
  max-width: 500px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;

  z-index: 1;
  @media ${mobile} {
    margin: unset;
    padding-top: unset;
  }
`;

const Container = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ContainerNew = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-top: 4vh;
  &:before {
    content: '';
    background: url(${BackGroundQuizImage});
    background-size: cover;
    position: absolute;
    right: 0;
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: -1;
  }

  @media ${mobile} {
    box-shadow: unset;
    padding-top: unset;
    overflow-x: hidden;
    &:before {
      content: '';
      background: unset;
      background-size: cover;
      position: absolute;
      right: 0;
      height: 100%;
      width: 100%;
      top: 0;
      z-index: -1;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  max-width: 50%;
  flex-direction: column;

  @media ${mobile} {
    max-width: calc(100% - 2rem);
  }
`;

const ContentContainerNew = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  box-shadow: rgba(254, 118, 119, 0.45) 0px 6px 40px;
  background: #fff;
  padding: 1rem 1.5rem 1.5rem 1.5rem;

  @media ${mobile} {
    padding: 1rem;
    box-shadow: unset;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
`;

const Subtitle = styled(Text)`
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0.5rem 0 1.5rem;
`;

const Footer = styled(Text)`
  text-align: center;
  width: 100%;
  padding-top: 1.5rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  color: #6a6a6a;
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${mobile} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)``;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();

  const { quiz_answers, user, geolocation, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();

  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  const dispatch = useDispatch();

  const { goToCheckout, goToResults, goToResultsSlides } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return goToResultsSlides();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
          }),
        )) ?? '';

      dispatch(updateQuizAnswers(trackingParams));

      cliendCodeToUrl(code);
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('steps')) {
        urlParams.delete('steps');
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }

      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : null,
        userState,
        user_ip,
      );

      handleNextRoute();
    } catch (error) {
      handleError(error);
      setError(error?.response.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <ContainerNew>
        <HeaderContainer>
          <HeaderNew logoVariant="center" sticky={false} />
        </HeaderContainer>
        <ContentContainerNew>
          <Title type="h1" color="dark100">
            {data?.title}
            {quiz_answers?.userName}
            {data?.title1}
          </Title>
          <Subtitle type="body" color="dark80">
            {data?.inputLabel}
          </Subtitle>
          <EmailFormStyled
            loading={loading}
            error={error}
            onSubmit={handleGetResults}
            continueBtnTitle={data?.continueBtnTitle}
            placeholder={data?.inputPlaceholder}
          />
          {data.img ? (
            <ImageContainer>
              <DynamicImage
                src={data.img}
                alt={data.alt}
                width="100%"
                height="100%"
              />
            </ImageContainer>
          ) : null}
          <Footer>{data?.footer}</Footer>
        </ContentContainerNew>
      </ContainerNew>
    </>
  );
};

export default Email;
